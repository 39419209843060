import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable, skipWhile } from 'rxjs';

import { OidcUserModel } from '~/api/auth/oidc-user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private oidc = inject(OidcSecurityService);

  readonly userData$: Observable<OidcUserModel> = this.oidc.userData$.pipe(
    skipWhile(({ userData }) => !userData),
    map(({ userData }) => userData),
  );

  readonly currentUser = toSignal(this.userData$);

  readonly userRoles = computed(() => {
    const { role } = this.currentUser() ?? {};

    if (!role) {
      return [];
    }

    return Array.isArray(role) ? role : [role];
  });

  isSuperAdmin = computed(() => this.userRoles().includes('SuperAdmin'));
  isAdmin = computed(() => this.userRoles().includes('Admin'));
  name = computed(() => this.currentUser()?.name ?? 'N/A');

  checkAuth() {
    return this.oidc.checkAuth();
  }

  authorize() {
    return this.oidc.authorize();
  }

  logout() {
    return this.oidc.logoff();
  }
}
